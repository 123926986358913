<template>
  <page-wrapper
    :isLoading="isLoading"
    :isExtended="true"
    :hasFilters="true"
    @submitFilters="GetAllBlockchains"
  >
    <template #title>Blockchain List</template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <b-form-group label="State" label-for="state">
        <v-select
          class="mr-md-2 w-auto"
          id="state"
          v-model="blockchainFilters.active"
          :options="activeOptions"
          :clearable="false"
          :reduce="(val) => val.value"
        />
      </b-form-group>
      <b-form-group label="Tron State" label-for="hasTron">
        <v-select
          class="mr-md-2 w-auto"
          id="hasTron"
          v-model="blockchainFilters.hasTron"
          :options="hasTronOptions"
          :clearable="false"
          :reduce="(val) => val.value"
        />
      </b-form-group>
      <b-form-group label="Count" label-for="count">
        <v-select
          class="mr-md-2 w-auto"
          id="count"
          v-model="blockchainFilters.count"
          :options="perPageOptions"
          :clearable="false"
        />
      </b-form-group>
    </template>
    <template #extend>
      <b-button
        variant="primary"
        class="mb-1 w-50 mr-1 btn-sm-block"
        @click="addAddress"
      >
        + Add Address
      </b-button>
      <b-button
        variant="warning"
        class="mb-1 w-50 btn-sm-block"
        @click="refreshWallets"
      >
        Update wallets
      </b-button>
    </template>
    <custom-table
      :pageNumber="blockchainFilters.pageNumber"
      :count="blockchainFilters.count"
      :totalCount="totalCount"
      :tableItems="blockchains"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #active="data">
        <p :class="data.item.active ? 'text-success' : 'text-danger'">
          {{ data.item.active ? "Active" : "Disable" }}
        </p>
      </template>
      <template #hasTron="data">
        <p
          class="text-success"
          :class="data.item.hasTron ? 'text-success' : 'text-danger'"
        >
          {{ data.item.hasTron ? "Has TRON" : "No Trons" }}
        </p>
      </template>
      <template #createDate="data">
        {{ data.item.createDate.slice(0, 10) }}
        -
        {{ data.item.createDate.slice(11, 19) }}
      </template>
      <template #actions="data">
        <span class="cursor-pointer">
          <feather-icon
            @click="showCredit(data.item.walletAddress)"
            icon="DollarSignIcon"
            size="20"
            class="text-primary"
          />
        </span>
      </template>
      <template #transfer="data">
        <span class="cursor-pointer">
          <feather-icon
            @click="selectForExternalTransfer(data.item)"
            icon="ExternalLinkIcon"
            size="20"
            class="text-success"
          />
        </span>
      </template>
    </custom-table>
    <b-modal
      id="modalTransfer"
      centered
      cancelTitle="Close"
      title="Transfer detail"
      ok-title="Submit"
      @ok="externalTransfer"
    >
      <b-col cols="12">
        <b-form-group label="Reciever Address" label-for="recieverAddress">
          <b-form-input id="recieverAddress" v-model="recieverAddress" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Amount" label-for="amount">
          <b-form-input id="amount" type="number" v-model="amount" />
        </b-form-group>
      </b-col>
    </b-modal>
  </page-wrapper>
</template>

<script>
import { BCol, BFormInput, BButton, BModal, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  WalletCreateTRC20AddressRequest,
  WalletExternalTransferByAdminRequest,
  WalletGetWalletDataRequest,
  WalletUpdateWalletsRequest,
} from "@/libs/Api/Wallet";
import { BlockchainGetAllRequest } from "@/libs/Api/BlockChain";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import axiosIns from "@/libs/axios";
import { saveAs } from "file-saver";

export default {
  title: "Blockchains List",
  name: "BlockchainsList",
  data() {
    return {
      blockchainFilters: {
        pageNumber: 1,
        count: 10,
        hasTron: null,
        active: null,
      },
      blockchains: null,
      totalCount: null,
      isLoading: false,
      walletCredit: "",
      perPageOptions: [10, 20, 30, 40, 50],
      activeOptions: [
        { label: "ALL", value: null },
        { label: "Active", value: true },
        { label: "Not Active", value: false },
      ],
      selectedForExternalTransfer: null,
      hasTronOptions: [
        { label: "ALL", value: null },
        { label: "Has Tron", value: true },
        { label: "No Tron", value: false },
      ],
      myTableColumns: [
        {
          key: "blockchainId",
          label: "Blockchain id",
        },
        {
          key: "userId",
          label: "User Id",
          formatter: (value) => {
            return value ? value : "-";
          },
        },
        {
          key: "walletAddress",
          label: "Address",
        },
        {
          key: "createDate",
          label: "Date",
        },
        {
          key: "active",
          label: "Active State",
        },
        {
          key: "hasTron",
          label: "TRON State",
        },
        {
          key: "actions",
          label: "Show Credit",
        },
        {
          key: "transfer",
          label: "External Transfer",
        },
      ],
      amount: "",
      recieverAddress: "",
    };
  },
  async created() {
    await this.GetAllBlockchains();
  },
  watch: {
    hasTron: function () {
      this.GetAllBlockchains();
    },
    active: function () {
      this.GetAllBlockchains();
    },
    currentPage: function () {
      this.GetAllBlockchains();
    },
    perPage: function () {
      this.GetAllBlockchains();
    },
  },
  methods: {
    changePage(pageNumber) {
      this.blockchainFilters.pageNumber = pageNumber;
      this.GetAllBlockchains();
    },
    async GetAllBlockchains() {
      try {
        this.isLoading = true;
        let _this = this;
        let blockchainGetAllRequest = new BlockchainGetAllRequest(_this);
        blockchainGetAllRequest.setParams(_this.blockchainFilters);
        await blockchainGetAllRequest.fetch(
          function (content) {
            _this.blockchains = content.blockchains;
            _this.totalCount = content.blockchainsCounts;
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async addAddress() {
      try {
        this.isLoading = true;
        let _this = this;
        let walletCreateTRC20AddressRequest =
          new WalletCreateTRC20AddressRequest(_this);
        await walletCreateTRC20AddressRequest.fetch(
          function (content) {
            _this.GetAllBlockchains();
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async showCredit(param) {
      try {
        this.isLoading = true;
        let _this = this;
        let data = {
          walletAddress: param,
        };
        let walletGetWalletDataRequest = new WalletGetWalletDataRequest(_this);
        walletGetWalletDataRequest.setParams(data);
        await walletGetWalletDataRequest.fetch(
          function (content) {
            _this.walletCredit = content.usdtCredit;
            _this.$bvModal.show("creditModal");
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async refreshWallets() {
      try {
        this.isLoading = true;
        let _this = this;

        let walletUpdateWalletsRequest = new WalletUpdateWalletsRequest(_this);
        await walletUpdateWalletsRequest.fetch(
          function (content) {
            _this.GetAllBlockchains();
          },
          function (error) {
            console.log(error);
          }
        );
        window.location.reload();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async externalTransfer() {
      try {
        this.isLoading = true;
        let _this = this;
        let data = {
          recieverAddress: _this.recieverAddress,
          amount: _this.amount,
          tatumWalletAddress: _this.selectedForExternalTransfer.walletAddress,
        };

        let walletExternalTransferByAdminRequest =
          new WalletExternalTransferByAdminRequest(_this);
        walletExternalTransferByAdminRequest.setParams(data);
        await walletExternalTransferByAdminRequest.fetch(
          function (content) {
            if (content.completed) {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `Done`,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `Something went wrong`,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          },
          function (error) {
            console.log(error);
          }
        );
        window.location.reload();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    selectForExternalTransfer(data) {
      this.selectedForExternalTransfer = data;
      this.$bvModal.show("modalTransfer");
    },
    async downloadFile() {
      try {
        this.isLoading = true;
        let userToken = getUserData().data.token;
        await axiosIns
          .post(`Blockchain/GetAllExportExcel`, null, {
            responseType: "blob",
            Authorization: userToken,
          })
          .then((resp) => {
            saveAs(resp.data, "blockchains.xlsx");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    vSelect,
    PageWrapper,
    CustomTable,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
</script>

<style scoped></style>
